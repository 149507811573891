<template>
    <a-modal
        title="退货 - 物流进度"
        width='50%'
        :visible="visible"
        :destroyOnClose="true"
        @cancel="handleCancel"
        :footer="null"
    >
        <a-spin :spinning="confirmLoading">
            <div style="margin-left:20px">
                <span style="font-weight:600;">承运：{{this.expressName}}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style="font-weight:600;">单号：{{this.expressNum}}</span>
                <br><br>
                <a-timeline>
                    <!-- 物流信息列表 -->
                    <div v-if="infoList.length > 0">
                        <a-timeline-item v-for="(item, index) in infoList" :key="index">
                            <span>
                                <p >{{item.context}}</p>
                                <p>{{item.time}}</p>
                            </span>
                        </a-timeline-item>
                    </div>
                    <!-- 查找不到物流信息 -->
                    <span v-else-if="message">未查到该物流信息</span>
                </a-timeline>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { 
  refundPageList,
  refundAudit,
  refundOk,
  refundDetail
} from '@/api/modular/mallLiving/refund'

import { orderDetail, wuliuInfo } from '@/api/modular/mallLiving/orderAdmin'

export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            id:'',
            details:{},
            expressName:'',
            expressNum:'',
            message:'123',
            infoList:[], //物流信息列表
            form: this.$form.createForm(this),
            record: {}
        }
    },
    methods:{
        //初始化方法
        express(record){
            this.visible = true
            this.confirmLoading = true
            this.record = record

            //获取详情数据
            setTimeout(()=>{
                refundDetail({id: record.id}).then((res)=>{
                    if(res.success){

                        this.expressNum = res.data.comCourierId  //展示的退货物流单号
                        this.expressName = res.data.comLogistics  //展示的退货物流公司名称

                        //调接口获取物流信息
                        wuliuInfo({
                            phone: '13699999999',
                            num:  res.data.comCourierId
                        }).then((res)=>{
                            if(res.success){
                                this.confirmLoading = false
                                if(res.data.data.data){
                                    this.infoList = res.data.data.data //物流信息列表
                                }else{
                                    this.message = res.data.data.message
                                }
                            }
                        })
                    }
                }).finally((res)=>{
                    setTimeout(()=>{
                        this.confirmLoading = false
                    },600)
                })
            },100)
            
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.infoList = [] //清空物流信息列表
            this.expressName = ''
            this.expressNum = ''
            this.message = ''
            this.visible = false
            this.confirmLoading = false
            setTimeout(()=>{
                this.details = {} //关闭之后清空
            },100)
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding:7px;
            font-size:15px;
            width:30%;
        }
    }
    img{
        width:80px;
        height: 50px;
    }
</style>