<template>
  <!-- 售后退款页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="24">
                <!-- 用户昵称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <!-- 订单号 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="订单号">
                    <a-input v-model="queryParam.orderNo" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="时间">
                    <a-range-picker allow-clear v-model="queryParam.time" show-time style="width: 320px" />
                  </a-form-item>
                </a-col>
                <!-- 退款类型 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="退款类型">
                    <a-select
                      v-model="queryParam.refundType"
                      allow-clear
                      placeholder="请选择"
                      @change="changeRefundType"
                    >
                      <a-select-option :value="1">退款</a-select-option>
                      <a-select-option :value="2">退货退款</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 当前退款流程 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="状态">
                    <!-- 当退款类型为 1 -->
                    <a-select
                      v-if="queryParam.refundType == 1"
                      v-model="queryParam.status"
                      allow-clear
                      placeholder="请选择"
                    >
                      <a-select-option :value="1">申请退款</a-select-option>
                      <a-select-option :value="2">商家同意退款</a-select-option>
                      <a-select-option :value="3">商家拒绝退款</a-select-option>
                    </a-select>
                    <!-- 当退款类型为 2 -->
                    <a-select
                      v-if="queryParam.refundType == 2"
                      v-model="queryParam.status"
                      allow-clear
                      placeholder="请选择"
                    >
                      <a-select-option :value="1">申请退货退款</a-select-option>
                      <a-select-option :value="2">商家同意退货退款</a-select-option>
                      <a-select-option :value="3">用户发货</a-select-option>
                      <a-select-option :value="4">商家确认收货</a-select-option>
                      <a-select-option :value="5">商家拒绝退货退款</a-select-option>
                    </a-select>
                    <!-- 当退款类型未选 -->
                    <a-select
                      v-if="queryParam.refundType != 1 && queryParam.refundType != 2"
                      v-model="queryParam.status"
                      allow-clear
                      placeholder="请选择"
                    >
                      <!-- 当退款类型未选，内部为空 -->
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 按钮 -->
                <a-col :md="8" :sm="24">
                  <a-button type="primary" @click="searchTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = { status: undefined })"
                    >重置
                  </a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table :columns="columns" :data-source="data" :scroll="{ x: 1300 }" :loading="loading" :pagination="false">
            <!-- 退款类型插槽 -->
            <span slot="refundType" slot-scope="text">
              {{ text == 1 ? '退款' : text == 2 ? '退货退款' : '' }}
            </span>
            <!-- 当前流程插槽 -->
            <span slot="status" slot-scope="text, record">
              <!-- 当 record.refundType==1 -->
              <span v-if="record.refundType == 1">
                {{ text == 1 ? '申请退款' : text == 2 ? '商家同意退款' : text == 3 ? '商家拒绝退款' : '' }}
              </span>
              <!-- 当 record.refundType==2 -->
              <span v-if="record.refundType == 2">
                {{
                  text == 1
                    ? '申请退货退款'
                    : text == 2
                      ? '商家同意退货退款'
                      : text == 3
                        ? '用户发货'
                        : text == 4
                          ? '商家确认收货'
                          : text == 5
                            ? '商家拒绝退货退款'
                            : ''
                }}
              </span>
            </span>
            <!-- 商品信息插槽 -->
            <!-- <span slot="orderGoodsJson" slot-scope="text">
              {{text}}
            </span> -->
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.detailForm.detail(record)">详情</a>
              <!-- 审核 -->
              <span
                v-if="(record.refundType == 1 && record.status == 1) || (record.refundType == 2 && record.status == 1)"
              >
                <a-divider type="vertical" />
                <a @click="$refs.auditForm.audit(record)">审核</a>
              </span>
              <!-- 退货物流 -->
              <span v-if="record.refundType == 2 && (record.status == 3 || record.status == 4)">
                <a-divider type="vertical" />
                <a @click="$refs.expressForm.express(record)">退货物流</a>
              </span>
              <!-- 确认收货 -->
              <span v-if="record.refundType == 2 && record.status == 3">
                <a-divider type="vertical" />
                <a-popconfirm placement="topRight" title="确认收货？" @confirm="() => handleRefundOk(record)">
                  <a>确认收货</a>
                </a-popconfirm>
              </span>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            >
            </a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <audit-form @ok="getList" ref="auditForm"></audit-form>
    <detail-form ref="detailForm"></detail-form>
    <express-form ref="expressForm"></express-form>
  </div>
</template>

<script>
import { refundPageList, refundAudit, refundOk, refundDetail } from '@/api/modular/mallLiving/refund'

import auditForm from './auditForm.vue'
import detailForm from './detailForm.vue'
import expressForm from './expressForm.vue'

const columns = [
  {
    title: '序号',
    align: 'left',
    width: '100px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '订单号',
    align: 'left',
    width: '150px',
    dataIndex: 'orderNo',
    key: 'center',
  },
  {
    title: '用户昵称',
    dataIndex: 'nickname',
    key: 'nickname',
    align: 'left',
  },
  {
    title: 'ID号',
    dataIndex: 'idNo',
    align: 'center',
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '退款类型',
    dataIndex: 'refundType',
    key: 'refundType',
    align: 'left',
    scopedSlots: {
      customRender: 'refundType',
    },
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    align: 'left',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '发起退款时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'left',
  },
  {
    title: '审核时间',
    dataIndex: 'checkTime',
    key: 'checkTime',
    align: 'left',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    align: 'left',
    width: '200px',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      queryParam: {
        status: undefined,
      },
    }
  },
  components: {
    auditForm,
    detailForm,
    expressForm,
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    //顶部筛选搜索
    searchTableData() {
      this.initTableData()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    onChange(date) {
      var date = new Date(date).getTime()
      // var year = date.getFullYear()
      // var month = date.getMonth() + 1
      // var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return date
    },
    getList() {
      this.loading = true
      if (this.queryParam.time && this.queryParam.time.length == 2) {
        this.queryParam.startTime = this.onChange(this.queryParam.time[0]._d)
        this.queryParam.endTime = this.onChange(this.queryParam.time[1]._d)
      } else {
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
      }
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      refundPageList(query)
        .then((res) => {
          if (res.success) {
            let data = res.data.rows
            // 将行数据中的json字符串转成数组
            // data.forEach((item)=>{
            //   item.orderGoodsJson = JSON.parse(item.orderGoodsJson)
            // })
            // 将转换后的data赋给this.data
            this.data = data
            console.log(data)

            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 确认收货
    handleRefundOk(record) {
      refundOk({
        id: record.id,
      }).then((res) => {
        if (res.success) {
          this.$message.success('确认收货成功')
          this.getList()
        }
      })
    },
    // 改变退款类型时候重置当前状态
    changeRefundType() {
      this.queryParam.status = undefined
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 20px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 20px;
  height: 20px;
}
</style>
